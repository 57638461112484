import React, { Component } from 'react'
import {
  Modal,
  Button,
  Form,
  FormGroup,
  FormControl,
  HelpBlock,
  ControlLabel,
  Col,
  Row,
  Grid,
  Radio,
  InputGroup,
  Glyphicon,
} from 'react-bootstrap'
import SearchableSelect from '../Common/PropertySelector'
import {} from '@luxuryescapes/lib-countries'
import SetRegionModal from '../Common/SetRegionsModal'
import * as libRegions from '@luxuryescapes/lib-regions'
import PromotionRequestService from '../../services/PromotionRequestService'
import MDSpinner from 'react-md-spinner'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

const FOURTEEN_DAYS_MILIS = 14 * 24 * 60 * 60 * 1000
const OTHER = 'Other - please type name'
const inclusionNames = [
  'Breakfast',
  'Room Upgrade Guaranteed',
  'Hotel Credit',
  'Lunch',
  '30 Min Massage',
  '60 Min Massage',
  'Spa Voucher',
  'Set Dinner for 2',
  'Daily Drink',
  'Free flow drink hour',
  OTHER,
]

export default class PromotionEditorModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        promotion_type: 'discount',
        discount_type: 'gross',
        discount_regions: libRegions.getRegions().map((region) => region.code),
        inclusion_currency: 'AUD',
        inclusions: [],
      },
      properties: [],
      loading: false,
      validate: false,
      error: null,
      openRegions: false,
      currencies: libRegions.getCurrencyCodes('luxuryescapes'),
    }
  }

  componentDidMount = () => {
    this.resetForm()
  };

  resetForm = () => {
    this.setState({
      formData: {
        promotion_type: 'discount',
        discount_type: 'gross',
        discount_regions: libRegions.getRegions().map((region) => region.code),
        inclusion_currency: 'AUD',
        inclusion_frequency: 'day',
        inclusions: [
          {
            sequence: 1,
            frequency: 'day',
          },
        ],
      },
      properties: [],
      loading: false,
      validate: false,
      error: null,
      openRegions: false,
      currencies: libRegions.getCurrencyCodes('luxuryescapes'),
    })
  };

  handleFieldChange = (e) => {
    const data = Object.assign({}, this.state.formData)
    data[e.target.name] = e.target.value || null
    this.setState({ formData: data })
  };

  hideConfirmationModal = (...args) => {
    this.resetForm()
    this.props.closeModal && this.props.closeModal(...args)
  };

  onSave = () => {
    this.setState({ validate: true }, this.save)
  }

  save = async() => {
    const allValid = this.validateAll()

    if (!allValid) {
      return
    }

    try {
      this.setState({ loading: true })
      const {
        propertyId: fk_property,
        rateId: fk_rate,
        roomId: fk_room,
        offerId: fk_offer_sf_id,
        start_date,
        end_date,
        promotion_type,
        discount_type,
        discount_amount,
        discount_regions,
        inclusions,
        inclusion_currency,
        inclusion_min_los,
        inclusion_max_los,
      } = this.state.formData
      await PromotionRequestService.create(this.props.idVendor, {
        fk_property,
        fk_rate,
        fk_room,
        fk_offer_sf_id,
        start_date,
        end_date,
        promotion_type,
        ...(this.hasDiscountData() && {
          discount_type,
          discount_amount,
          discount_regions,
        }),
        ...(this.hasInclusionData() && {
          inclusions,
          inclusion_currency,
          inclusion_min_los,
          inclusion_max_los,
        }),
      })
      this.hideConfirmationModal(true)
    } catch (err) {
      console.warn('ERROR-->', this.state.formData)
    } finally {
      this.setState({ loading: false })
    }
  };

  validateRequired = (key) => {
    if (this.state.validate) {
      if (!this.state.formData?.[key]) {
        return 'error'
      }

      switch (key) {
        case 'end_date':
        {
          const { start_date, end_date } = this.state.formData

          if ((new Date(start_date).getTime() + FOURTEEN_DAYS_MILIS) >= new Date(end_date).getTime()) {
            return 'before_14_days'
          }
        }
      }
    }
    return null
  };

  validateDiscountAmount = () => {
    if (this.state.validate) {
      const amount = this.state.formData.discount_amount

      if (!amount) {
        return 'error'
      } else if (this.state.formData.discount_type === 'gross') {
        if (amount < 5 || amount >= 100) {
          return 'error'
        }
      } else if (this.state.formData.discount_type === 'net') {
        if (amount < 20) {
          return 'error'
        }
      }
    }
    return null
  };

  validateMinLOS = () => {
    if (this.state.validate) {
      const value = this.state.formData.inclusion_min_los
      if (!value) {
        return 'error'
      }
      if (value < 1 || value > 10) {
        return 'error'
      }
    }
    return null
  };

  validateMaxLOS = () => {
    if (this.state.validate) {
      const value = this.state.formData.inclusion_max_los
      if (!value) {
        return 'error'
      }
      if (value < 1 || value > 99) {
        return 'error'
      }
    }
    return null
  };

  validateInclusions = () => {
    if (this.state.validate) {
      const value = this.state.formData.inclusions
      if (!value?.length) {
        return 'error'
      }
      const all_valid = value.reduce(
        (acc, value) =>
          !!value.name && !!value.value && !!value.frequency && acc,
        true,
      )
      if (!all_valid) {
        return 'error'
      }
    }
    return null
  };

  validateInclusion = (inclusion, key) => {
    if (this.state.validate) {
      if (!inclusion[key]) {
        return 'error'
      }
    }
    return null
  };

  validateAll = () => {
    const validateDiscountData = () =>
      (this.hasDiscountData() ?
        [
          this.validateDiscountAmount(),
          ...['discount_regions', 'discount_type'].map((key) => this.validateRequired(key)),
        ] :
        []
      ).reduce((acc, val) => acc && !val, true)
    const validateInclusionData = () =>
      (this.hasInclusionData() ?
        [
          this.validateMinLOS(),
          this.validateMaxLOS(),
          this.validateInclusions(),
          ...['inclusion_currency'].map((key) => this.validateRequired(key)),
        ] :
        []
      ).reduce((acc, val) => acc && !val, true)

    return (
      validateDiscountData() &&
      validateInclusionData() &&
      ['roomId', 'start_date', 'end_date', 'promotion_type']
        .map((key) => this.validateRequired(key))
        .reduce((acc, val) => acc && !val, true)
    )
  };

  hasDiscountData = () => ['discount', 'inclusion_discount'].includes(this.state.formData.promotion_type);

  hasInclusionData = () => ['inclusion', 'inclusion_discount'].includes(this.state.formData.promotion_type);

  getRegionNameFromCode = (code) => {
    const region = libRegions.getRegionByCode(code, 'luxuryescapes')
    return region ? region.name : code
  };

  renderInclusionName = (
    onUpdate,
    { ref, defaultValue, onKeyDown, onBlur, row },
  ) => (
    <Col sm={12} md={12}>
      <FormGroup
        controlId="name"
        style={{ marginBottom: 0 }}
        validationState={this.validateInclusion(row, 'name')}
      >
        {!row.isInput ?
          <FormControl
            componentClass="select"
            name="name"
            placeholder="Inclusion name"
            onChange={(e) => {
              row.isInput = e.target.value === OTHER
              onUpdate(e.target.value)
            }}
            defaultValue={defaultValue}
            ref={ref}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
          >
            {inclusionNames.map(inc => <option key={inc} value={inc}>{inc}</option>)}
          </FormControl> :
          <FormControl
            type="text"
            name="name"
            placeholder="Inclusion name"
            defaultValue={defaultValue}
            onKeyDown={onKeyDown}
            onBlur={(e) => {
              onUpdate(e.target.value)
              onBlur(e)
            }}
            ref={ref}
          />}
      </FormGroup>
    </Col>
  );

  renderInclusionValue = (
    onUpdate,
    { ref, defaultValue, onKeyDown, onBlur, row },
  ) => (
    <Col sm={12} md={12}>
      <FormGroup
        controlId="value"
        style={{ marginBottom: 0 }}
        validationState={this.validateInclusion(row, 'value')}
      >
        <FormControl
          type="number"
          name="value"
          placeholder="Inclusion value"
          ref={ref}
          defaultValue={defaultValue}
          onKeyDown={onKeyDown}
          onBlur={(e) => {
            onUpdate(e.target.value)
            onBlur(e)
          }}
        />
      </FormGroup>
    </Col>
  );

  renderInclusionFrequency = (
    onUpdate,
    { ref, defaultValue, onKeyDown, onBlur, row },
  ) => (
    <Col sm={12} md={12}>
      <FormGroup controlId="frequency" style={{ marginBottom: 0 }}>
        <FormControl
          componentClass="select"
          name="frequency"
          placeholder="Frequency"
          onChange={(e) => onUpdate(e.target.value)}
          ref={ref}
          value={defaultValue}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        >
          <option key="night" value="night">Per Night</option>
          <option key="guest" value="guest">Per Guest</option>
          <option key="day" value="day">Per Stay</option>
        </FormControl>
      </FormGroup>
    </Col>
  );

  frequencyRender = (cell, row) => {
    switch (row.frequency) {
      case 'night':
        return 'Per Night'
      case 'day':
        return 'Per Stay'
      case 'guest':
        return 'Per Guest'
    }
  };

  updateInclusionData = (row, cellName, cellValue) => {
    const inclusion = this.state.formData.inclusions.find((inc) => inc.sequence === row.sequence)
    Object.assign(inclusion, { [cellName]: cellValue })
    return true
  };

  addInclusion = () => {
    if (this.state.formData.inclusions.length < 10) {
      this.state.formData.inclusions.push({
        sequence: this.state.formData.inclusions.length + 1,
        name: '',
        value: null,
        frequency: 'day',
      })
      this.setState({ formData: this.state.formData })
    }
  };

  removeInclusion = (sequence) => {
    const inclusion = this.state.formData.inclusions.find((inc) => inc.sequence == sequence)
    if (inclusion) {
      this.state.formData.inclusions.splice(this.state.formData.inclusions.indexOf(inclusion), 1)
    }
    this.state.formData.inclusions.forEach((inc, i) => (inc.sequence = i + 1))
    this.setState({ formData: this.state.formData })
  };

  dropInclusionButton = (cell, row) => (
    <Button
      bsStyle="danger"
      onClick={() => {
        this.removeInclusion(row.sequence)
      }}
    >
      <Glyphicon glyph="trash" style={{ color: 'inherit' }} />
    </Button>
  );

  render() {
    const { openModal, idVendor } = this.props
    const { formData, openRegions, currencies, loading } = this.state
    return (
      <Modal
        className="static-modal dates-request-reject"
        bsSize="small"
        show={openModal}
        onHide={this.hideConfirmationModal}
      >
        {loading && (
          <div className="spinner-container overlay">
            <MDSpinner className="spinner" size={30} />
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>Create promotion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form horizontal>
            <Grid>
              <Row>
                <Col xs={12} sm={12}>
                  <FormGroup
                    controlId="propertyId"
                    validationState={this.validateRequired('rateId')}
                  >
                    <ControlLabel>Property</ControlLabel>
                    <SearchableSelect
                      idVendor={idVendor}
                      onChange={(rateIds) => {
                        this.setState({ formData: { ...formData, ...rateIds } })
                      }}
                      name="rateId"
                    />
                    {this.validateRequired('rateId') && <HelpBlock>
                      Select a rate
                    </HelpBlock>}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup
                    controlId="start_date"
                    validationState={this.validateRequired('start_date')}
                  >
                    <ControlLabel>Promotion start date</ControlLabel>
                    <FormControl
                      type="date"
                      name="start_date"
                      placeholder="Select date"
                      value={formData.start_date}
                      onChange={this.handleFieldChange}
                    />
                    {this.validateRequired('start_date') && <HelpBlock>
                      Select the start date
                    </HelpBlock>}
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup
                    controlId="end_date"
                    validationState={this.validateRequired('end_date') && 'error'}
                  >
                    <ControlLabel>Promotion end date</ControlLabel>
                    <FormControl
                      type="date"
                      name="end_date"
                      placeholder="Select date"
                      value={formData.end_date}
                      onChange={this.handleFieldChange}
                    />
                    {{
                      error: <HelpBlock>Select the end date</HelpBlock>,
                      before_14_days: <HelpBlock>End date must be at least 2 weeks after start date</HelpBlock>,
                    }[this.validateRequired('end_date')]}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="travellerFormCol" xs={12} sm={12}>
                  <FormGroup
                    controlId="promotion_type"
                    validationState={this.validateRequired('promotion_type')}
                  >
                    <ControlLabel>Promotion type</ControlLabel>
                    <FormControl
                      componentClass="select"
                      name="promotion_type"
                      placeholder="Promotion type"
                      defaultValue={formData.promotion_type}
                      onChange={this.handleFieldChange}
                    >
                      <option key="discount" value="discount">Discount</option>
                      <option key="inclusion" value="inclusion">Inclusion</option>
                      <option key="inclusion_discount" value="inclusion_discount">Inclusion and Discount</option>
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
              {this.hasDiscountData() && (
                <Row>
                  <Col className="travellerFormCol" xs={12} sm={12}>
                    <FormGroup
                      controlId="discount_type"
                      onChange={this.handleFieldChange}
                      validationState={this.validateRequired('discount_type')}
                    >
                      <ControlLabel>Discount type</ControlLabel>
                      <Radio
                        value="gross"
                        name="discount_type"
                        checked={formData.discount_type === 'gross'}
                        inline
                      >
                        Gross
                      </Radio>{' '}
                      <Radio
                        value="net"
                        name="discount_type"
                        checked={formData.discount_type === 'net'}
                        inline
                      >
                        Net
                      </Radio>
                    </FormGroup>
                  </Col>

                  <Col sm={12} md={12}>
                    <FormGroup
                      controlId="discount_amount"
                      validationState={this.validateDiscountAmount()}
                    >
                      <ControlLabel>Discount amount</ControlLabel>
                      <InputGroup>
                        {formData.discount_type === 'net' && (
                          <InputGroup.Addon>$</InputGroup.Addon>
                        )}
                        <FormControl
                          type="number"
                          name="discount_amount"
                          placeholder="Discount amount"
                          value={formData.discount_amount}
                          onChange={this.handleFieldChange}
                        />
                        {formData.discount_type === 'gross' && (
                          <InputGroup.Addon>%</InputGroup.Addon>
                        )}
                      </InputGroup>
                      {this.validateDiscountAmount() && <HelpBlock>
                        {this.state.formData.discount_type === 'gross' && 'Discount amount should be between 5 and 100'}
                        {this.state.formData.discount_type === 'net' && 'Discount amount should be less than 20'}
                      </HelpBlock>}
                    </FormGroup>
                  </Col>

                  <Col className="travellerFormCol" xs={12} sm={12}>
                    <FormGroup
                      controlId="discount_regions"
                      validationState={this.validateRequired(
                        'discount_regions',
                      )}
                    >
                      <ControlLabel>Regions</ControlLabel>

                      <ul>
                        {formData.discount_regions?.length ===
                        libRegions.getRegions().length ? (
                            <li key="world">world</li>
                          ) : (
                          formData.discount_regions?.map((region) => (
                            <li key={region}>{this.getRegionNameFromCode(region)}</li>
                          ))
                          )}
                      </ul>

                      <Button
                        bsStyle="primary"
                        onClick={() => {
                          this.setState({ openRegions: true })
                        }}
                      >
                        Select Regions
                      </Button>
                      <SetRegionModal
                        openModal={openRegions}
                        closeModal={() => {
                          this.setState({ openRegions: false })
                        }}
                        onChange={(value) => {
                          const data = Object.assign({}, this.state.formData, { discount_regions: value ?? [] })
                          this.setState({ formData: data, openRegions: false })
                        }}
                        regions={formData.discount_regions}
                        name="Select Regions"
                        label="Tag all regions that you want this package to be visible in"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}

              {this.hasInclusionData() && (
                <Row>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'end', marginBottom: '8px' }}>
                    <small><i>Click at cell to edit.</i></small>
                    <Button bsStyle="primary" onClick={this.addInclusion} style={{ marginRight: '8px' }}>
                      Add inclusion
                    </Button>
                  </div>
                  <BootstrapTable
                    data={formData.inclusions}
                    cellEdit={{
                      mode: 'click',
                      afterSaveCell: this.updateInclusionData,
                    }}
                  >
                    <TableHeaderColumn
                      dataField="sequence"
                      isKey={true}
                      width="10%"
                    >
                      Seq.
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="name"
                      customEditor={{ getElement: this.renderInclusionName }}
                    >
                      Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="value"
                      customEditor={{ getElement: this.renderInclusionValue }}
                    >
                      Value
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="frequency"
                      dataFormat={this.frequencyRender}
                      customEditor={{
                        getElement: this.renderInclusionFrequency,
                      }}
                    >
                      Frequency
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataFormat={this.dropInclusionButton}
                      editable={false}
                      width="10%"
                    ></TableHeaderColumn>
                  </BootstrapTable>

                  <Col className="travellerFormCol" xs={12} sm={12}>
                    <FormGroup
                      controlId="inclusion_currency"
                      validationState={this.validateRequired(
                        'inclusion_currency',
                      )}
                    >
                      <ControlLabel>Currency</ControlLabel>
                      <FormControl
                        componentClass="select"
                        name="inclusion_currency"
                        placeholder="Currency"
                        defaultValue={formData.inclusion_currency}
                        onChange={this.handleFieldChange}
                      >
                        {currencies.map((curr) => (
                          <option key={curr} value={curr}>{curr}</option>
                        ))}
                      </FormControl>
                      {this.validateRequired('inclusion_currency') && <HelpBlock>
                        Select an inclusion currency
                      </HelpBlock>}
                    </FormGroup>
                  </Col>

                  <Col className="travellerFormCol" xs={12} sm={6} md={6}>
                    <FormGroup
                      controlId="inclusion_min_los"
                      validationState={this.validateMinLOS()}
                    >
                      <ControlLabel>Min Length of Stay (1-10)</ControlLabel>
                      <FormControl
                        type="number"
                        name="inclusion_min_los"
                        placeholder="Min Length of Stay"
                        value={formData.inclusion_min_los}
                        onChange={this.handleFieldChange}
                      />
                      {this.validateMinLOS() && <HelpBlock>
                        Min Length of Stay should be between 1 and 10
                      </HelpBlock>}
                    </FormGroup>
                  </Col>

                  <Col className="travellerFormCol" xs={12} sm={6} md={6}>
                    <FormGroup
                      controlId="inclusion_max_los"
                      validationState={this.validateMaxLOS()}
                    >
                      <ControlLabel>Max Length of Stay (1-99)</ControlLabel>
                      <FormControl
                        type="number"
                        name="inclusion_max_los"
                        placeholder="Max Length of Stay"
                        value={formData.inclusion_max_los}
                        onChange={this.handleFieldChange}
                      />
                      {this.validateMaxLOS() && <HelpBlock>
                        Max Length of Stay should be between 1 and 99
                      </HelpBlock>}
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </Grid>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize="large" onClick={this.hideConfirmationModal}>
            Cancel
          </Button>
          <Button
            bsSize="large"
            bsStyle="primary"
            active={false}
            onClick={this.onSave}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
