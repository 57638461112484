import React, { Component } from 'react'
import {
  Modal,
  Button,
  Form,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Checkbox,
} from 'react-bootstrap'
import * as libRegions from '@luxuryescapes/lib-regions'

export const asiaRegions = [
  'China',
  'Hong Kong',
  'India',
  'Indonesia',
  'Israel',
  'Japan',
  'Korea',
  'Macau',
  'Malaysia',
  'Philippines',
  'Qatar',
  'Saudi Arabia',
  'Singapore',
  'Taiwan',
  'Thailand',
  'United Arab Emirates',
  'Vietnam',
]

export default class SetRegionModal extends Component {
  constructor(props) {
    super(props)

    const selectedRegions = props.regions ?? []

    const regions = libRegions.getRegionNamesAndCode().map((region) => ({
      ...region,
      isChecked:
        selectedRegions.includes('world') ||
        selectedRegions.includes(region.code),
    }))

    this.state = {
      regions: regions,
    }

    this.selectAll = this.selectAll.bind(this)
    this.deselectAll = this.deselectAll.bind(this)
    this.selectAsiaRegions = this.selectAsiaRegions.bind(this)
    this.getCheckedRegions = this.getCheckedRegions.bind(this)
  }

  getCheckedRegions() {
    return this.state.regions.flatMap((region) =>
      region.isChecked ? [region.code] : [],
    )
  }

  changeSelection(value) {
    this.setState((prevState) => ({
      regions: prevState.regions.map((region) => ({
        ...region,
        isChecked: value,
      })),
    }))
  }

  selectAll() {
    this.changeSelection(true)
  }

  deselectAll() {
    this.changeSelection(false)
  }

  selectAsiaRegions(isAsia) {
    this.setState((prevState) => ({
      regions: prevState.regions.map((region) =>
        asiaRegions.includes(region.name) ?
          { ...region, isChecked: isAsia } :
          { ...region, isChecked: !isAsia },
      ),
    }))
  }

  regionsChanges(e, region) {
    const checked = e.target.checked
    const name = region.name

    this.setState((prevState) => {
      const updatedRegions = prevState.regions.map((region) => {
        if (region.name === name) {
          const updatedRegion = {
            ...region,
            isChecked: checked,
          }
          return updatedRegion
        }

        return region
      })

      return { regions: updatedRegions }
    })
  }

  render() {
    return (
      <Modal
        className="static-modal dates-request-reject"
        bsSize="small"
        show={this.props.openModal}
        onHide={this.props.closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>
              <Button bsStyle="default" onClick={this.selectAll}>
                Select all
              </Button>
              <Button bsStyle="default" onClick={this.deselectAll}>
                Deselect all
              </Button>
              <Button
                bsStyle="default"
                onClick={() => this.selectAsiaRegions(true)}
              >
                Asia only
              </Button>
              <Button
                bsStyle="default"
                onClick={() => this.selectAsiaRegions(false)}
              >
                Non-Asian only
              </Button>
            </div>
          </Form>

          <div>
            {this.state.regions.map((region) => (
              <Col key={region.code} xs={4}>
                <Checkbox
                  inline
                  key={region.code}
                  checked={region.isChecked}
                  onChange={(e) => this.regionsChanges(e, region)}
                >
                  {region.name}
                </Checkbox>
              </Col>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.closeModal} bsSize="large">
            Cancel
          </Button>
          <Button
            bsSize="large"
            bsStyle="primary"
            onClick={() => {
              this.props.onChange(this.getCheckedRegions())
            }}
          >
            {this.props.saveButton || 'Set Regions'}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
