import React from 'react'
import { Link } from 'react-router'
import { Glyphicon, Grid, Col, Row, Button } from 'react-bootstrap'
import moment from 'moment-timezone'
import SalesPerformance from './SalesPerformance'
import ScheduleHistory from './ScheduleHistory'
import Image from '../Common/Image'

const getImage = (offer) => {
  if (offer.images && offer.images.length !== 0) {
    return (
      <Image id={offer.images[0].id_cloudinary_external} />
    )
  }
  return null
}

const formatDate = (val) => moment(val).format('DD MMMM YYYY')

const getDate = (schedules, brand, dateType) => {
  const schedule = schedules[brand].schedule
  if (schedule && schedule[dateType]) {
    return formatDate(schedule[dateType])
  } else {
    return 'Unknown'
  }
}

const getAvailabilityPropertyLink = (vendorId, offer) => {
  if (offer.type !== 'hotel' || !offer.packages.length) {
    return
  }

  const contentApprovedPackageWithProperty = offer.packages.find(pkg => pkg.status === 'content-approved' && pkg.fk_property_id)
  if (contentApprovedPackageWithProperty) {
    return `/${vendorId}/properties/${contentApprovedPackageWithProperty.fk_property_id}/availability`
  }
  return null
}

const getOrdersLink = ({ vendorId, offer }) => `/${vendorId}/${offer.id_salesforce_external}/orders`

const getScheduleDates = (schedules, type) => Object.keys(schedules).map(function(key, index) {
  const startDate = getDate(schedules, key, type)
  if (startDate === 'Unknown') {
    return null
  }
  return <li key={index}>{key}: {startDate}</li>
})

export default function OfferCard({ id_vendor, offer }) {
  const availibilityPropertyLink = getAvailabilityPropertyLink(id_vendor, offer)
  const schedules = offer.availability_schedules

  return (
    <div className="offer-card">
      <Grid>
        <Row>
          <Col sm={12} md={2}>
            {getImage(offer)}
            <h5>Deal ID</h5>
            {offer.id_salesforce_external}
            {<h5>LE Deal ID</h5>}
            {offer.le_offer_id}
          </Col>
          <Col sm={12} md={7}>
            <h4>{offer.name}</h4>
            {offer.description}
          </Col>
          <Col sm={12} md={3}>
            <h5>
              Schedule Dates {' '}
              <ScheduleHistory
                offerId={offer.id_salesforce_external}
                formatDate={formatDate}
              />
            </h5>
            {getScheduleDates(schedules, 'start')}
            <h5>Offer End Dates</h5>
            {getScheduleDates(schedules, 'end')}
            <h5>Travel Until Date</h5>
            {offer.travel_to_date ? formatDate(offer.travel_to_date) : 'Unknown'}
            <h5>Reservation Email</h5>
            {offer.vendor_booking_email ? offer.vendor_booking_email : 'Unknown'}
            <h5>Offer Type</h5>
            {offer.type}
          </Col>
        </Row>

        <SalesPerformance idOffer={offer.id_salesforce_external} />
        <Row className="action-bar">
          <Col sm={12} md={6}>
            <div className="deal-links">
              {Object.keys(schedules).filter(k => schedules[k].schedule && schedules[k].host).map(k => (
                <Button key={k} bsStyle="link" onClick={() => {
                  window.open(`${schedules[k].host}/offer/${offer.slug}/${offer.id_salesforce_external}?preview=true`, '_blank')
                }}>
                  <Glyphicon glyph="list-alt" />View Deal Page on {k}
                </Button>
              ))}
            </div>
          </Col>

          <Col sm={12} md={6}>
            <div className="button-group">
              {availibilityPropertyLink && offer.self_managed && (
                <Link to={availibilityPropertyLink} className="btn btn-primary"><Glyphicon glyph="calendar" />Edit Availability</Link>
              )}
              <Link to={getOrdersLink({ vendorId: id_vendor, offer })} className="btn btn-primary"><Glyphicon glyph="briefcase" />View Bookings</Link>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}
