import React, { Component } from 'react'
import MDSpinner from 'react-md-spinner'
import {
  Button,
  Col,
  ControlLabel,
  Dropdown,
  Form,
  FormControl,
  FormGroup,
  MenuItem,
} from 'react-bootstrap'
import PropertyService from '../../services/PropertyService'
import OffersService from '../../services/OffersService'

class PropertyToggle extends Component {
  handleClick = (e) => {
    e.preventDefault()
    this.props.onClick(e)
    setTimeout(() => {
      this.inputRef.focus()
    }, 100)
  };

  render() {
    const { open, onSearch, selected } = this.props

    return (
      <div>
        <FormControl
          type="text"
          onChange={onSearch}
          placeholder="Search property..."
          inputRef={(ref) => {
            this.inputRef = ref
          }}
          style={{ display: !open && 'none', width: '100%' }}
        />
        {!open && (
          <Button onClick={this.handleClick} style={{ width: '100%' }}>
            {selected?.name ?? 'Select property'}
          </Button>
        )}
      </div>
    )
  }
}

export default class PropertySelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      items: [],
      selectedProperty: null,
      searching: false,
    }
    this.timer = null
  }

  componentDidMount = async() => {
    this.fetchItems()
  };

  handleChange = (event) => {
    const query = event.target.value
    this.setState({ query })

    // Debounce
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.fetchItems(query)
    }, 300)
  };

  fetchItems = async(query) => {
    try {
      this.setState({ loading: true })
      const [{ result }, { result: properties }] = await Promise.all(
        [
          PropertyService.listPropertiesByVendor(1, 20, this.props.idVendor, query),
          this.properties ?? OffersService.getOffersByVendor(1, 50, this.props.idVendor),
        ],
      )

      const available_packages = properties
        .filter(({ salesforce_record_type, status, schedules }) =>
          salesforce_record_type === 'Dynamic Rate Products - Approved' &&
          status === 'content-approved' &&
          schedules.some(sched => new Date(sched.end).getTime() > Date.now()),
        )
        .map(({ packages }) => packages)
        .flat()

      const items = result
        .filter(property => {
          property.room_rates = property.room_types
            .filter(room_type => {
              room_type.room_rates = room_type.room_rates.filter(rate => {
                const pkg = available_packages
                  .find(({ fk_room_type_id, fk_property_id, fk_room_rate_id }) =>
                    property.id === fk_property_id &&
                    room_type.id === fk_room_type_id &&
                    rate.id === fk_room_rate_id,
                  )

                Object.assign(rate, { offerId: pkg?.offer_id_salesforce_external })

                return !!pkg
              })
              return room_type.room_rates?.length > 0
            })
            .map(room_type => room_type.room_rates)
            .flat()
            .filter((item, i, self) => self.indexOf(self.find(t => t.offerId === item.offerId)) === i)

          return property.room_rates?.length > 0
        })

      this.setState({ items })
    } catch (error) {
      console.error('Erro ao buscar itens:', error)
    } finally {
      this.setState({ loading: false })
    }
  };

  render() {
    const { items, selectedProperty, selectedRate, loading } =
      this.state
    return (
      <div style={{ width: '100%', marginBottom: 0 }}>
        <Dropdown id="dd-prop-selector" style={{ width: '100%' }}>
          <PropertyToggle
            bsRole="toggle"
            onSearch={this.handleChange}
            selected={selectedProperty}
          ></PropertyToggle>

          <Dropdown.Menu bsRole="menu">
            {!loading &&
              items.map((item) => (
                <MenuItem
                  key={item.id}
                  eventKey={item.id}
                  onClick={() => {
                    this.setState({
                      selectedProperty: item,
                      selectedRate: null,
                    })
                  }}
                >
                  {item.name}
                </MenuItem>
              ))}
            {loading && <MDSpinner className="spinner" size={25} />}
          </Dropdown.Menu>
        </Dropdown>
        <Col className="rateId" xs={12} sm={12}>
          <FormGroup controlId="formControlsSelect" style={{ marginBottom: 0 }}>
            <ControlLabel>Select rate</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              onChange={(e) => {
                const selRate = selectedProperty?.room_types?.find(
                  (type) => type.id == e.target.value,
                )
                this.setState({ selectedRate: selRate })
                this.props.onChange &&
                  this.props.onChange({
                    rateId: selRate?.id,
                    propertyId: selectedProperty?.id,
                    offerId: selRate?.offerId,
                  })
              }}
            >
              <option value="">Select rate</option>
              {selectedProperty?.room_types?.map((rate) => (
                <option value={rate.id} key={rate.id}>
                  {rate.name}
                </option>
              ))}
            </FormControl>
          </FormGroup>
        </Col>
      </div>
    )
  }
}
